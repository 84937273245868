import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ lang, meta }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            canonical
          }
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={site.siteMetadata.title}
      link={[
        {
          rel: 'canonical',
          href: site.siteMetadata.canonical
        }
      ]}
      meta={[
        {
          name: `description`,
          content: site.siteMetadata.description
        },
        {
          name: `author`,
          content: site.siteMetadata.author
        },
        {
          name: `keywords`,
          content: site.siteMetadata.keywords
        }
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
};

export default SEO;
